import get from 'lodash/get'
import { getSessionJSON, removeSessionItem, setSessionJSON } from './storage'

export const iam = () => get(window, 'cb.core.iam')
export const getCatapultId = () => iam().getTempAWSCredsSubject()
export const getCBEnv = () => iam().getCBEnv()
export const getAuthSession = () => iam().getAuthSession() || {}
export const getAuthorizationToken = () => iam().getAuthorizationToken()
export const getAuthenticationToken = () => iam().getAuthenticationToken()

export const ROLE_SCHOOL = '820' // HS CLEP Coordinator
export const ROLE_DISTRICT = '821' // District CLEP Coordinator
export const ROLE_INTERNAL = '823' // Internal User
export const ROLES = [ROLE_SCHOOL, ROLE_DISTRICT, ROLE_INTERNAL]

export const getDecodedToken = () => {
  const token = getAuthorizationToken()
  const { cb } = token ? JSON.parse(window.atob(token.split('.')[1])) : {}
  return cb || {}
}
export const getProfile = () => {
  const { em, un, pid } = getDecodedToken()
  const {
    basicProfile: { firstName, lastName },
  } = iam().getAuthSession() || {}
  return { firstName, lastName, username: un, email: em, personId: pid }
}
export const getRoles = (role) => {
  const { ro } = getDecodedToken()
  return ro?.filter((r) => ROLES.includes(r.rid) && (!role || r.rid === role)) || []
}

export const isProfessional = () => getDecodedToken()?.ns === 'pf'
export const isStudent = () => getDecodedToken()?.ns === 'st'
export const getAllowedOrgs = () => getRoles().map((r) => r.oid)
export const isSchool = () => getRoles(ROLE_SCHOOL).length > 0
export const isDistrict = () => getRoles(ROLE_DISTRICT).length > 0
export const isInternal = () => getRoles(ROLE_INTERNAL).length > 0

export const canViewApp = () => isSchool() || isDistrict() || isInternal()

const HISTORY_KEY = 'history'
const areRoutesEqual = (a, b) => a?.pathname === b?.pathname && a?.search === b?.search

export const getUserHistory = () => {
  const { id, routes = [] } = getSessionJSON(HISTORY_KEY)
  return id === getCatapultId() ? routes : []
}

export const updateUserHistory = (route) => {
  const existing = getUserHistory()
  const count = existing.length
  const isReplace = areRoutesEqual(route, existing[count - 2])
  const isDuplicate = areRoutesEqual(route, existing[count - 1])
  let update = [...existing, route]

  if (isReplace) update = [...existing.slice(0, count - 2), route]
  else if (isDuplicate) update = [...existing.slice(0, count - 1), route]

  setSessionJSON(HISTORY_KEY, { id: getCatapultId(), routes: update })
}

export const getPreviousRoute = () => {
  const history = getUserHistory()
  return history[history.length - 2] || { pathname: '/dashboard' }
}

export const deleteUserHistory = () => removeSessionItem(HISTORY_KEY)
