import { useEffect } from 'react'
import { useApolloClient, useSubscription } from '@apollo/client'
import { GET_CACHED_COMMON_DATA, GET_ORDERS_BY_ORG_ID } from '@/services/graphql/professionals/queries'
import { SUBSCRIPTION_ORDER_UPDATED } from '@/services/graphql/professionals/subscriptions'
import { getQueryName, useCustomQuery } from '@/services/graphql/utils'
import { useDistrictUserState } from './useProfessionalBaseData'

// Updates "GET_ORDERS_BY_ORG_ID (getOrders)" query response in apollo cache
// when AppSync pushes a notification about status change of an order
export const useOrderStatusChangeSubscription = () => {
  const client = useApolloClient()
  const { data: { selectedOrg, selectedSchool, currentEduPeriod } = {} } = useCustomQuery(GET_CACHED_COMMON_DATA)
  const { isDistrictUserViewingSchool } = useDistrictUserState()
  const targetOrg = isDistrictUserViewingSchool ? selectedSchool : selectedOrg

  const queryOptionsPerOrg = {
    variables: {
      orgId: targetOrg?.orgId,
      educationPeriodCd: currentEduPeriod?.code,
    },
    skip: !(targetOrg?.orgId && currentEduPeriod?.code),
  }

  const ordersQuery = useCustomQuery(GET_ORDERS_BY_ORG_ID, queryOptionsPerOrg)
  const orderUpdatedGql = useSubscription(SUBSCRIPTION_ORDER_UPDATED, {
    variables: { orgId: targetOrg?.orgId },
    skip: !targetOrg?.orgId,
  })

  useEffect(() => {
    const nextOrder = orderUpdatedGql.data?.orderUpdated?.order
    if (!nextOrder) return

    const nextOrders = (ordersQuery.data || []).map((order) =>
      order.orderId === nextOrder.orderId
        ? {
            ...order,
            status: nextOrder.status,
            purchaseOrderNumber: nextOrder.purchaseOrderNumber,
          }
        : order
    )

    client.cache.writeQuery({
      query: GET_ORDERS_BY_ORG_ID,
      ...queryOptionsPerOrg,
      data: {
        [getQueryName(GET_ORDERS_BY_ORG_ID)]: nextOrders,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUpdatedGql])
}
