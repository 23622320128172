import { makeVar } from '@apollo/client'

export const currentEduPeriodVar = makeVar()
export const registrationFormsVar = makeVar([
  {
    voucherCode: undefined,
    examProductCd: undefined,
    isExamDantesFunded: undefined,
    diCodes: undefined,
    skipDiCodeInd: undefined,
    remoteProctoringInd: undefined,
    dantesEligibleInd: undefined,
    accommodations: undefined,
    acceptedTerms: undefined,
    acceptedOrderTerms: undefined,
    studyMaterialProducts: undefined,
    noScoreSendsAllowed: undefined,
    promoCode: undefined,
  },
])
export const profileFormVar = makeVar({})

export const DEFAULT_FLOW_PROGRESS = { stepCount: 0, progress: [], exitRoute: undefined }
export const flowProgressVar = makeVar(DEFAULT_FLOW_PROGRESS)
export const paymentProcessorVar = makeVar({})

export const studentMessagesVar = makeVar([])

export const studentCancellationProductsVar = makeVar([])

export const studentRequestTranscriptVar = makeVar({
  selectedScores: [],
  selectedCollege: undefined,
  acceptedTerms: undefined,
})
export const orderStudyMaterialVar = makeVar({
  examProductCds: [],
  dantesEligibleInd: undefined,
  acceptedTerms: undefined,
  studyMaterialProducts: [],
})
