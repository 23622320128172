import axios from 'axios'
import orderBy from 'lodash/orderBy'
import { formatDay } from '@clep/utilities/helpers'
import { createCache } from '@clep/utilities/cache'
import { PRODUCT_CODE_PROCTOR, PRODUCT_CODE_TRANSCRIPT } from '@/utils/constants'
import getEnvConfig from '../../utils/environment'

export const refCache = createCache({ keyPrefix: 'reference:' })

const makeApiCall = (endpoint) => {
  const { referenceApiUrl: apiBase, referenceApiKey: apiKey } = getEnvConfig()
  return axios({
    url: apiBase + endpoint,
    method: 'GET',
    headers: {
      'x-api-key': apiKey,
    },
  })
}

export const getCurrentEduPeriod = async () => {
  const cacheKey = 'current-edu-period'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/enterprise?type=CURRENT_ED_PERIOD')
      return data
    } catch (err) {
      throw new Error('Unable to get current education period.')
    }
  })
}

export const getEduPeriods = async () => {
  const cacheKey = 'edu-periods'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/enterprise?type=ED_PERIODS')
      return (data || []).sort((a, b) => b.code - a.code)
    } catch (err) {
      throw new Error('Unable to get education periods.')
    }
  })
}

export const getOrganizationTypes = async () => {
  const cacheKey = `organization-types`
  return refCache.getItem(cacheKey, async () => {
    const { data } = await makeApiCall(`/org/types`)
    return orderBy(data, ['displayOrder'], 'asc')
  })
}

export const getAccessReasons = async () => {
  const cacheKey = `access-reasons`
  return refCache.getItem(cacheKey, async () => {
    const { data } = await makeApiCall(`/access/reasons`)
    return orderBy(data, ['displayOrder'], 'asc').map((e) => ({
      value: e.accessReqReasonCd,
      label: e.accessReqReasonName,
    }))
  })
}

export const getFundingTypes = async () => {
  const cacheKey = `funding-types`
  return refCache.getItem(cacheKey, async () => {
    const { data } = await makeApiCall(`/funding/types`)
    return orderBy(data, ['displayOrder'], 'asc')
  })
}

export const getEduLevels = async (edLevelType) => {
  const cacheKey = `edlevels:${edLevelType}`
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/edlevels')
      return data
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .filter((e) => (edLevelType ? e.type === edLevelType : true))
    } catch (err) {
      throw new Error('Unable to get education levels.')
    }
  })
}

export const getEduCategories = async () => {
  const cacheKey = 'edcategories'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/edcategories')
      return data.sort((a, b) => a.displayOrder - b.displayOrder)
    } catch (err) {
      throw new Error('Unable to get education categories.')
    }
  })
}

export const getProductPrices = async () => {
  const cacheKey = 'product-prices'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/pricing')
      const { prices } = data

      return {
        exam: prices?.EXAM,
        proctor: prices?.SERVICES && prices?.SERVICES[PRODUCT_CODE_PROCTOR],
        transcript: prices?.SERVICES && prices?.SERVICES[PRODUCT_CODE_TRANSCRIPT],
        material: prices?.PDF,
      }
    } catch (err) {
      return {}
    }
  })
}

export const getProducts = async () => {
  const cacheKey = 'products'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/products')
      return data
    } catch (err) {
      throw new Error('Unable to get products.')
    }
  })
}

export const getProductsByType = async (productType) => {
  const data = await getProducts()
  return data.filter((item) => item.productType === productType)
}

export const getExamCategories = async () => {
  const cacheKey = 'exam-categories'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/products/exams/categories')
      return data.sort((a, b) => a.displayOrder - b.displayOrder)
    } catch (err) {
      throw new Error('Unable to get exam categories.')
    }
  })
}

export const getAdminWindows = async (educationPeriodCd) => {
  const cacheKey = `admin-windows:${educationPeriodCd}`
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall(`/adminwindows/edperiods/${educationPeriodCd}`)
      return data
    } catch (err) {
      throw new Error(`Unable to get admin windows ${educationPeriodCd}.`)
    }
  })
}

export const getEthnicities = async (enableMapping = false) => {
  const cacheKey = 'ethnicities'
  const list = await refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall(`/ethnicities`)
      return orderBy(data, ['displayOrder'], 'asc')
    } catch (err) {
      throw new Error('Unable to get ethnicities.')
    }
  })

  if (!enableMapping) return list
  return new Map(list.map((item) => [item.ethnicityCd, item]))
}

export const getRaces = async (enableMapping = false) => {
  const cacheKey = 'races'
  const list = await refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/races')
      return orderBy(data, ['displayOrder'], 'asc')
    } catch (err) {
      throw new Error('Unable to get races.')
    }
  })

  if (!enableMapping) return list
  return new Map(list.map((item) => [item.raceCd, item]))
}

export const getLanguages = async () => {
  const cacheKey = 'languages'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/languages')
      return orderBy(data, ['displayOrder'], 'asc')
    } catch (err) {
      throw new Error('Unable to get languages.')
    }
  })
}

export const getDantesCategories = async () => {
  const cacheKey = 'dantes:categories'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/dantes/categories')
      return orderBy(data, ['displayOrder'], 'asc')
    } catch (err) {
      throw new Error('Unable to get /dantes/categories.')
    }
  })
}

export const getDantesOptionsForMembers = async () => {
  const cacheKey = 'dantes:categories:members'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/dantes/categories/members')
      return data
    } catch (err) {
      throw new Error('Unable to get /dantes/categories/members.')
    }
  })
}

export const getDantesOptionsForCivilians = async () => {
  const cacheKey = 'dantes:categories:civilians'
  return refCache.getItem(cacheKey, async () => {
    try {
      const { data } = await makeApiCall('/dantes/categories/civilians')
      return data
    } catch (err) {
      throw new Error('Unable to get /dantes/categories/civilians.')
    }
  })
}

/**
 * Fetches promotions for a given date, utilizing a cache to store results.
 *
 * @param {string} asOfDate - The date for which to fetch promotions, formatted as a string.
 * @returns {Promise<Object>} A promise that resolves to the promotions data.
 * @throws {Error} Throws an error if unable to get promotions.
 */
export const getPromotions = async (asOfDate) => {
  const cacheKey = `promotions${asOfDate}`
  return refCache.getItem(cacheKey, async () => {
    try {
      const queryParams = asOfDate ? `?asOfDate=${asOfDate}` : ''
      const { data } = await makeApiCall(`/promotions${queryParams}`)
      return data
    } catch (err) {
      throw new Error('Unable to get promotions.')
    }
  })
}

/**
 * Retrieves the available promotions as of today
 * @returns {Promise<Array>} A promise that resolves to an array of promotions.
 * @throws {Error} If unable to get promotions.
 */
export const getAvailablePromotions = async () => {
  const asOfDate = formatDay({ date: new Date(), format: 'yyyy-MM-dd' })
  return getPromotions(asOfDate)
}
