import { useLazyQuery, useQuery } from '@apollo/client'

export const getQueryName = (query) => query.definitions?.[0]?.name?.value

export const mergeStatuses = (statuses) =>
  statuses.slice(1).reduce(
    (obj, status) => ({
      loading: Boolean(obj.loading || status?.loading),
      error: obj.error || status?.error,
      loaded: Boolean(obj.loaded !== false && status?.loaded !== false),
    }),
    statuses[0]
  )

export const formatAppsyncErrors = (error) => {
  const { message } = error || {}
  const formattedMsg = message ? message.replace('GraphQL error:', '').trim() : null
  return !error ? null : formattedMsg || 'Please try again.'
}

export const formatResults = (data, transform) => {
  const result = (data && Object.values(data)[0]) || undefined
  return transform && result ? transform(result) : result
}

export const useCustomQuery = (query, options, transform) => {
  const { loading, error, data, refetch } = useQuery(query, options)
  const err = formatAppsyncErrors(error)

  return {
    refetch, // fetch policy is automatically set to network-only for data refetches
    loading,
    error: err,
    loaded: Boolean(data),
    data: formatResults(data, transform),
  }
}

export const useCustomLazyQuery = (query, options, transform) => {
  const [fetch, { loading, data, error, called, refetch }] = useLazyQuery(query, options)
  const err = formatAppsyncErrors(error)

  return {
    fetch,
    refetch,
    loading,
    error: err,
    loaded: Boolean(data),
    data: formatResults(data, transform),
    called,
  }
}

export const useCustomMultipleQuery = (query, options) => {
  const { loading, error, data, refetch } = useQuery(query, options)
  const err = formatAppsyncErrors(error)

  return {
    refetch, // fetch policy is automatically set to network-only for data refetches
    loading,
    error: err,
    loaded: Boolean(data),
    data: data && Object.values(data),
  }
}
