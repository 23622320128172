import { getCBEnv, isStudent } from './user'

export const PROD = 'pine'
const REGION = 'us-east-1'

const REFERENCE_API_KEYS = {
  dev: 'NxqmMNKNzKXtw4dWXBd7bWSSDZbxr0mT',
  qa: 't78HlrJaD4MNJIH8iIfE7RNTlSRzDO58',
  qaauto: '0hL48v8c1zKhDeSLOHzibjkvMWfmDCvY',
  uat: '8kvX5Qj7ZhV027wGXpKQ4pjogrsXlgTl',
  prod: 'zZMFRd5GUfhkckAVkEI7JdNf6h632c6q',
  preprod: 'gKAtrj5CcMx0E1yWxbtOBHo2B5JNjpIV',
}

const MSORG_API_KEYS = {
  nonprod: 'URq6e87Yu37uWHnZ1MnXv3RPZrGItaLGo2gHjOG1',
  prod: 'sJbrQDHW0K5bYNtuezxvW2zFYhH3YVVf1FO43tyQ',
}

export default () => {
  const cbEnv = getCBEnv() || PROD // default to prod
  const [envByOrigin] = window.location.hostname.split('.')
  const nonProdClepEnv = Config.CLEP_ENV || (envByOrigin.includes('local') ? 'dev' : envByOrigin)
  const appsyncUserDomain = isStudent() ? 'student' : 'prf'

  if (cbEnv === PROD)
    return {
      cbUrl: 'https://organization.cds-prod.collegeboard.org/pine',
      cbApiKey: 'bCjAE1TxfW4hKsUviAckx6AU5yDMZQ5m29aCmhdH',
      appsyncUrl: isStudent()
        ? 'https://clep-student-api.collegeboard.org/graphql'
        : 'https://clep-prf-api.collegeboard.org/graphql',
      appsyncRegion: REGION,
      referenceApiUrl: 'https://clep-reference-api.collegeboard.org/prod',
      referenceApiKey: REFERENCE_API_KEYS.prod,
      msorgApiUrl: 'https://organization.cds-prod.collegeboard.org/pine',
      msorgApiKey: MSORG_API_KEYS.prod,
      legacyAppUrl: 'https://clepportal.collegeboard.org/clepcand/home/dashboard.action',
    }

  return {
    cbUrl: `https://organization.cds-nonprod.collegeboard.org/spruce`,
    cbApiKey: 'FY2IO65gu57QRF1mj4Sr6CV2CAjCW578StWoEs2g',
    appsyncUrl: `https://${appsyncUserDomain}-${nonProdClepEnv}.clep-nonprod.collegeboard.org/graphql`,
    appsyncRegion: REGION,
    referenceApiUrl: `https://reference.clep-nonprod.collegeboard.org/${nonProdClepEnv}`,
    referenceApiKey: REFERENCE_API_KEYS[nonProdClepEnv],
    msorgApiUrl: `https://organization.cds-nonprod.collegeboard.org/${cbEnv}`,
    msorgApiKey: MSORG_API_KEYS.nonprod,
    legacyAppUrl: `https://clepportal-${cbEnv}.clep-nonprod.collegeboard.org/clepcand/home/dashboard.action`,
  }
}
