import { validateString } from '@clep/utilities/helpers'
import { isEmailValid, isPhoneInputValid } from '@/utils/helpers'

export const contactFormValidator = (formData, required = true, isPrincipal = false) => {
  const { firstName = '', lastName = '', email = '', title = '', phone = '' } = formData || {}
  const errors = {
    firstName:
      !(!required && !firstName) &&
      !validateString({ str: firstName, allowedChars: "a-zA-Z\\s\\-\\'", maxLength: 20 }) &&
      'Error: Please enter a valid first name (only letters, spaces, hyphens, or single quotes).',
    lastName:
      !(!required && !lastName) &&
      !validateString({ str: lastName, allowedChars: "a-zA-Z\\s\\-\\'", maxLength: 30 }) &&
      'Error: Please enter a valid last name (only letters, spaces, hyphens, or single quotes).',
    title:
      !(!required && !title) &&
      !isPrincipal &&
      !validateString({ str: title || '', allowedChars: "a-zA-Z0-9\\s\\-\\.\\,\\:\\_\\,\\~\\'", maxLength: 100 }) &&
      'Error: Please enter only letters, numbers, spaces, hyphens, underscores, tildes, periods, commas, apostrophes, or colons.',
    email:
      !(!required && !email) &&
      !isEmailValid(email) &&
      'Error: Please enter a valid email address (example: name@mail.com).',
    phone:
      !(!required && !phone) && !isPhoneInputValid(phone) && 'Error: Please enter a valid phone number (numbers only).',
  }

  return Object.values(errors).filter(Boolean).length > 0 && errors
}
