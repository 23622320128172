import CBData from '@cb/apricot/CBData'

export const QUERY_PARAM_TAB = 'tab'
export const QUERY_PARAM_ORDER_ID = 'orderId'
export const COLUMN_SIZE_FORMS = 'col-md-8 col-lg-6'
export const COLUMN_SIZE_STEPPER = 'col-md-4'
export const COLUMN_OFFSET = 'offset-md-1'
export const PRODUCT_CODE_VOUCHER = 'CLEP-SVC-VOUCHER'
export const PRODUCT_CODE_PROCTOR = 'CLEP-SVC-REMOTEPROCTOR'
export const PRODUCT_CODE_TRANSCRIPT = 'CLEP-SVC-TRANSCRIPT'
export const PRODUCT_TYPE_EXAM = 'EXAM'
export const PRODUCT_TYPE_PDF = 'PDF'
export const ADMIN_TYPE_TESTCENTER = 'TESTCENTER'
export const ADMIN_TYPE_INSCHOOL = 'INSCHOOL'

export const SITE_NAME = 'CLEP'
export const CONTACT_EMAIL = 'clep@collegeboard.org'

export const PROF_REQUEST_TYPE = {
  ACCESS: 'accessRequest',
}

export const PROF_ACCESS_REASON_CODE = {
  ORDER_VOUCHERS: '1',
  GET_SCORES: '2',
  OPEN_TEST_CENTER: '3',
  OTHER: '4',
}

export const ORG_TYPE_CODE = {
  NONEDU: '7',
  NONEDU2: '9',
  HS: '1',
  DISTRICT: '6',
}

export const CARD_TYPE = {
  DISCOVER: 'Discover',
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
  AMEX: 'American Express',
}

export const ORDER_TYPE = {
  REG_EGUIDE: 'REG_EGUIDE',
}

export const ORDER_STATUS = {
  PROCESSED: 'PROCESSED',
  CANCELED: 'CANCELED',
  PENDING_ECOM: 'PENDING_ECOM',
  PROCESSING: 'PROCESSING',
}

export const VOUCHER_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
  USED: 'USED',
}

export const REG_STATUS = {
  EXPIRED: 'EXPIRED',
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  HOLD: 'HOLD',
  CONSUMED: 'CONSUMED',
  COMPLETED: 'COMPLETED',
  NOT_RESTARTABLE: 'NOT_RESTARTABLE',
  CHECKED_IN: 'CHECKED_IN',
  NOT_COMPLETE: 'NOT_COMPLETE',
}

export const EDLEVEL_TYPE = {
  COLLEGE: 'COLLEGE',
  HIGH_SCHOOL: 'HIGH_SCHOOL',
}

export const PRICING_TYPE = {
  PAYMENT: 'payments',
  REFUND: 'refunds',
}

export const SCORES_TYPE = {
  OFFICIAL: 'OFFICIAL',
}

export const DANTES_CATEGORY = {
  MEMBER: 'MEMBER', // Member of the military
  CIVILIAN: 'CIVILIAN', // Air Force civilian employee
  SPOUSE: 'SPOUSE', // Spouse of someone in the military
}

export const DANTES_CIVILIAN_CODE = {
  COAST_GUARD_SPOUSE: '0205',
  MY_CAA_APPROVED: '0210',
  AIR_FORCE_CIVILIAN_EMPLOYEE: '0512',
}

export const COUNTRY_CODE = {
  US: 'US',
}

export const PHONE_NUMBER_LENGTH = {
  US: 11, // exact 11 digits including leading '1'
  NON_US_MAX: 15, // max 15 digits including country code
}

export const US_STATE_OPTIONS = CBData.states.map((e) => ({ value: e.value, label: e.name })).slice(1) // Removes 'Choose a State'

export const COUNTRY_OPTIONS = [
  { value: COUNTRY_CODE.US, label: 'United States' }, // Puts "US" to the top of the list
  ...CBData.countries
    .map((e) => ({ value: (e.iso || '').toUpperCase(), label: e.name }))
    .filter((e) => e.value !== COUNTRY_CODE.US),
]

export const FEATURES = {
  STUDENT_APP: 'featureStudentApp',
  PREVIOUS_EDU_PERIODS: 'featurePreviousEduPeriods',
}

export const ERROR_ICON_TYPES = {
  LOAD_FAILED: 'load_failed',
  NO_INFO: 'no_info',
}

export const MESSAGE_TYPE = {
  PRIMARY: 'primary',
  DANGER: 'danger',
  INFO: 'info',
}

export const LOCAL_STORAGE_KEYS = {
  UNPAID_ORDER_ID: 'UNPAID_ORDER_ID',
  TRANSCRIPT_FLOW: 'TRANSCRIPT_FLOW',
}

export const SENDSCORE_STATUS = {
  PENDING: 'PENDING',
  SENT: 'SENT',
  FULFILLED: 'FULFILLED',
  ERROR: 'ERROR',
  CANCELED: 'CANCELED',
}

export const SCORE_STATUS = {
  PENDING: 'PENDING', // if score is null
  HOLD: 'HOLD',
  OFFICIAL: 'OFFICIAL',
  CANCELED: 'CANCELED',
}
export const COMMUNITY_COLLEGE_AIRFORCE_KEY = 'ccaf'
export const COMMUNITY_COLLEGE_AIRFORCE_CODE = '6139'

export const US_DIAL_COUNTRY_CODES = CBData.countries.filter((c) => c.dialCode === '1').map((c) => c.iso)

export const EDIT_PROFILE_LINK = `https://my.collegeboard.org/profile/information?navId=st-acct&appId=${Config.CLEP_APP_ID}`

export const TRANSCRIPT_ORDERING_SERVICE_LINK =
  'https://exchange.parchment.com/send/adds/index.php?main_page=index&s_id=3q7ey35yiWKgVEiC'
