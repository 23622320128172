import { GET_ENABLED_FEATURES } from '@/services/graphql/students/queries'
import { FEATURES } from '@/utils/constants'
import { useCustomQuery } from '@/services/graphql/utils'

export const useFeatureFlags = ({ skip = false } = {}) => {
  const { data, loading, error } = useCustomQuery(GET_ENABLED_FEATURES, { skip })
  const { features } = data || {}
  const isStudentAppEnabled = (features || []).includes(FEATURES.STUDENT_APP)
  const isPreviousEduPeriodsEnabled = (features || []).includes(FEATURES.PREVIOUS_EDU_PERIODS)

  return {
    isStudentAppEnabled,
    isPreviousEduPeriodsEnabled,
    loading,
    error,
  }
}
