import { Link } from 'react-router-dom'
import CBLocalNavigation from '@cb/apricot/CBLocalNavigation'

import NavigationItem from './NavigationItem'
import { SITE_NAME } from '../../../utils/constants'

const Navigation = ({ title = SITE_NAME, routes }) => {
  const navRef = useRef(null)

  useEffect(() => {
    CBLocalNavigation({
      elem: navRef.current,
      mobileInteractionClose: true,
      dropdownCloseOnClick: true,
    })
  }, [])

  return (
    <div
      ref={navRef}
      className="cb-local-navigation cb-white-bg cb-box-shadow-bottom"
      data-cb-apricot="local-navigation"
    >
      <div className="cb-desktop-navigation container">
        <div className="row">
          <nav className="col-xs cb-nav-container" aria-label="Site Navigation">
            <div className="cb-site-name">
              <Link to="/">{title}</Link>
            </div>
            <div className="cb-nav-items hidden-xs-only flex-fill flex-grow-0">
              <div className="cb-menu-list cb-menu-list-horizontal">
                <ul>
                  {routes.map((route, index) => (
                    <NavigationItem {...route} key={`${route.path}-${index}`} />
                  ))}
                </ul>
              </div>
            </div>
            <div className="cb-mobile-trigger hidden-sm-up">
              <a
                href="#"
                className="cb-glyph cb-menu cb-no-text-decoration cb-link-black"
                aria-expanded="false"
                tabIndex="-1"
                aria-hidden="true"
              >
                <span className="sr-only">Display Site Navigation</span>
              </a>
            </div>
          </nav>
        </div>
      </div>
      <div className="cb-mobile-navigation" />
    </div>
  )
}

Navigation.propTypes = {
  routes: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default Navigation
