import { Navigate, useLocation } from 'react-router-dom'
import Router from '@/components/apps/router/Router'
import Error from '@/components/ui/error/Error'
import CenterSpinner from '@/components/ui/loader/CenterSpinner'
import { useFeatureFlags } from '@/hooks/useFeatureFlags'
import { useSelectedSchoolQueries } from '@/hooks/useProfessionalBaseData'
import { useOrderStatusChangeSubscription } from '@/hooks/useOrderStatusChangeSubscription'
import SecondaryNavigation from '@/pages/professionals/app/SecondaryNavigation'
import { GET_CACHED_COMMON_DATA } from '@/services/graphql/professionals/queries'
import { useCustomQuery } from '@/services/graphql/utils'
import { appInitError } from '@/utils/messages'
import { PROF_LINKS, PROF_MAIN_NAVIGATION, PROF_ROUTES } from '@/utils/professionals'
import useProfessionalAppData from './useProfessionalAppData'

const NO_ACCESS_PAGES = [PROF_LINKS.noaccess, PROF_LINKS.accessrequestform, PROF_LINKS.accessrequestconfirm]
const ORG_PROFILE_SETUP_PAGES = [PROF_LINKS.orgprofilesetup, PROF_LINKS.orgprofileconfirm]

const ProfessionalApp = () => {
  const { pathname } = useLocation()
  const { data: { currentEduPeriod, selectedOrg } = {} } = useCustomQuery(GET_CACHED_COMMON_DATA)
  const { isAuthorized, loading, error, hasAcceptedTC, eduPeriods, orgProfile } = useProfessionalAppData()
  const { isPreviousEduPeriodsEnabled } = useFeatureFlags({ skip: !isAuthorized })

  useSelectedSchoolQueries()
  useOrderStatusChangeSubscription()

  const isOrgProfileComplete = selectedOrg?.adminInd === true || Boolean(orgProfile)

  if (loading) return <CenterSpinner />

  if (error) return <Error {...appInitError} wrapperClass="cb-margin-top-72 col-sm-12" />

  if (!isAuthorized && !NO_ACCESS_PAGES.includes(pathname)) return <Navigate to={PROF_LINKS.noaccess} />

  if (isAuthorized && !hasAcceptedTC && pathname !== PROF_LINKS.pfterms) return <Navigate to={PROF_LINKS.pfterms} />

  if (isAuthorized && pathname === PROF_LINKS.noaccess) return <Navigate to={PROF_LINKS.dashboard} />

  if (hasAcceptedTC && pathname === PROF_LINKS.pfterms) return <Navigate to={PROF_LINKS.dashboard} />

  if (hasAcceptedTC && !isOrgProfileComplete && !ORG_PROFILE_SETUP_PAGES.includes(pathname) && currentEduPeriod.current)
    return <Navigate to={PROF_LINKS.orgprofilesetup} />

  if (hasAcceptedTC && isOrgProfileComplete && ORG_PROFILE_SETUP_PAGES.includes(pathname))
    return <Navigate to={PROF_LINKS.dashboard} />

  return (
    <Router
      routeComponents={PROF_ROUTES}
      routePaths={PROF_LINKS}
      defaultRoute={PROF_LINKS.dashboard}
      mainNavItems={PROF_MAIN_NAVIGATION}
      subNavComponent={
        <SecondaryNavigation eduPeriods={isPreviousEduPeriodsEnabled ? eduPeriods : [currentEduPeriod]} />
      }
    />
  )
}

export default ProfessionalApp
