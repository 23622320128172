/* istanbul ignore file */
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client'
import getEnvConfig from '../../utils/environment'
import { getAuthorizationToken, isStudent } from '../../utils/user'
import professionalPolicies from './professionals/policies'
import studentPolicies from './students/policies'

let client = null

export const appsyncClient = () => {
  const { appsyncUrl: url, appsyncRegion: region } = getEnvConfig()
  const auth = { type: AUTH_TYPE.AWS_LAMBDA, token: () => `cbtoken-${getAuthorizationToken()}` }

  if (!client) {
    client = new ApolloClient({
      link: ApolloLink.from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink({ url, region, auth }, createHttpLink({ uri: url })),
      ]),
      cache: new InMemoryCache({ typePolicies: isStudent() ? studentPolicies : professionalPolicies }),
    })
  }

  return client
}
