import { useEffect } from 'react'
import * as gqlCache from '@/services/graphql/professionals/cache'
import {
  GET_CACHED_COMMON_DATA,
  GET_ORG_HIERARCHY,
  GET_ORG_PROFILE,
  GET_ORGS_BY_ID,
} from '@/services/graphql/professionals/queries'
import { useCustomQuery } from '@/services/graphql/utils'
import { isDistrict } from '@/utils/user'

export const useOrgProfileQuery = () => {
  const { data: { selectedOrg, currentEduPeriod } = {} } = useCustomQuery(GET_CACHED_COMMON_DATA)
  const { loading, data, error, refetch } = useCustomQuery(GET_ORG_PROFILE, {
    variables: { orgId: selectedOrg?.orgId, educationPeriodCd: currentEduPeriod?.code },
    skip: !currentEduPeriod || !selectedOrg,
  })

  return { loading, data, refetch, error }
}

export const useOrgHierarchyQuery = (isDistrictUser) => {
  const { data: { selectedOrg } = {} } = useCustomQuery(GET_CACHED_COMMON_DATA)

  const queryRes = useCustomQuery(GET_ORG_HIERARCHY, {
    variables: { orgId: selectedOrg?.orgId },
    skip: !(isDistrictUser && selectedOrg),
  })

  return queryRes
}

export const useDistrictUserState = () => {
  const isDistrictUser = isDistrict()
  const { data: { selectedSchool } = {} } = useCustomQuery(GET_CACHED_COMMON_DATA)

  return {
    isDistrictUser,
    isDistrictUserViewingSchool: isDistrictUser && Boolean(selectedSchool),
    isDistrictUserViewingDistrict: isDistrictUser && !selectedSchool,
  }
}

// @TODO: replace with "useSelectedOrgQueries" once "selectedSchool" is removed
export const useSelectedSchoolQueries = () => {
  const { data: { selectedSchool } = {} } = useCustomQuery(GET_CACHED_COMMON_DATA)

  const queryRes = useCustomQuery(GET_ORGS_BY_ID, {
    variables: { orgIds: [selectedSchool?.orgId] },
    skip: !selectedSchool,
  })

  useEffect(() => {
    if (queryRes.data?.[0]) {
      gqlCache.selectedSchoolVar(queryRes.data[0])
    }
  }, [queryRes.data])

  return {
    loading: queryRes.loading,
  }
}
