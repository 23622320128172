import { getCatapultId, iam } from '../utils/user'

export const DEFAULT_AUTH = {
  loading: false,
  loggedIn: false,
  loggedOut: true,
  error: false,
}

export const useAuth = () => {
  const { getEventBus, events } = iam()
  const bus = getEventBus()
  const { Logout, AWSLogin, AWSLoginFailed } = events
  const hasLoginCookie = document.cookie.includes('cb_login')
  const hasAWSCreds = Boolean(getCatapultId())
  const [auth, setAuth] = useState({
    loading: hasLoginCookie && !hasAWSCreds,
    loggedIn: hasAWSCreds,
    loggedOut: !hasLoginCookie && !hasAWSCreds,
    error: false,
  })

  /* istanbul ignore next */
  bus.on(Logout, () => setAuth(DEFAULT_AUTH))
  /* istanbul ignore next */
  bus.on(AWSLogin, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, loggedOut: false }))
  /* istanbul ignore next */
  bus.on(AWSLoginFailed, () => setAuth({ ...DEFAULT_AUTH, loggedIn: true, error: true, loggedOut: false }))

  return auth
}
