import { gql } from '@apollo/client'

export const SUBSCRIPTION_ORDER_UPDATED = gql`
  subscription orderUpdated($orgId: String!) {
    orderUpdated(orgId: $orgId) {
      orgId
      order {
        orderId
        status
        purchaseOrderNumber
      }
    }
  }
`

export const NOTIFY_SCORE_REPORT_STATUS = gql`
  subscription notifyScoreReportStatus($requestId: String!) {
    notifyScoreReportStatus(requestId: $requestId) {
      requestId
      scoreReportStatus
      presignedUrl
    }
  }
`
