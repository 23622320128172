import { ERROR_ICON_TYPES } from '@/utils/constants'

export const appInitError = {
  title: 'CLEP Could Not Load',
  message: 'An error has occurred while initializing CLEP. Please refresh the page to try again.',
}

export const accessDeniedError = {
  title: 'Access Denied',
  message:
    'You do not have access to use CLEP. If you think this is incorrect or would like access, please contact Customer Service.',
}

export const apiError = {
  title: 'Unable to Load Data',
  message: 'We are experiencing a problem processing this right now. Please try again.',
}

export const noInfoAvailableError = {
  iconType: ERROR_ICON_TYPES.NO_INFO,
  title: 'No Information Available',
  message:
    'It appears this school has not set up their CLEP organization profile for the current academic year so no information can be displayed. If this is incorrect, please confirm with the school. Otherwise, select another school from the menu above.',
}

export const saveError = {
  title: 'Unable to Save Data',
  message: 'We are experiencing a problem processing this right now. Please try again.',
}

export const voucherInvalidMessage =
  'Voucher code not valid. Please check and re-enter your code. If you continue to experience issues, please contact the person who gave you the voucher code.'

export const voucherIsInSchool =
  'Your voucher code is for in-school testing only. Subjects covered by this voucher may be limited.'

export const voucherAlreadyUsedMessage = `This voucher code was already applied to another exam. Please enter a different voucher code or click No to continue.`

export const requiredErrorMsg = 'Error: Field is required.'
export const comboErrorMsg = 'Error: These selections may not be combined together.'

export const orderProcessingErrorMsg = 'An error has occurred while processing your order.'
export const unableToProcessPaymentErrorMsg = `We are unable to process your payment at this time. Please try again later`
export const invalidCreditCardErrorMsg = `The credit card number you entered is invalid. Please check the number and try again.`
export const inactivityErrorMsg = `Your request has timed out due to inactivity. Please try again.`

export const saveOrgErrorMessage = {
  invalidPhone: `We couldn't save your profile because the phone number you entered is invalid. Please enter a valid phone number and try again.`,
}

export const saveOrgQueryErrorMessage = {
  invalidPhone: `variable 'phone' has an invalid value.`,
}

// STUDENTS
export const RECENT_ORDER_MESSAGE = {
  type: 'success',
  title: 'Success!',
  text: `Your CLEP exam registration(s) has been processed. If you don't see your exam information below, please refresh the page.`,
}

export const CANCEL_ORDER_MESSAGE = {
  type: 'success',
  title: 'Success!',
  text: `Your item(s) have been canceled and removed from this page.`,
}

export const TRANSCRIPT_ORDER_MESSAGE = {
  type: 'success',
  title: 'Success!',
  text: `Your Transcript Order is complete. View full order details on the Order History tab and your transcript status updates on the Scores tab.`,
}

export const EGUIDE_ORDER_MESSAGE = {
  type: 'success',
  title: 'Success!',
  text: `Your E-guide order is complete. It may take a few minutes for it to be reflected on this page.`,
}

export const promoCodeSuccessMessage = `Code can only be applied one time to one exam for each test taker.`
export const promoCodeInvalidMessage = `Error: This promo code is invalid. Please try again.`
export const promoCodeCantBeAppliedMessage = `This promo code can't be applied to this order.`
