import { lazy, Suspense } from 'react'
import { Navigate, useRoutes, matchRoutes, useLocation } from 'react-router-dom'
import { SITE_NAME } from '@/utils/constants'
import { capitalizePhrase } from '@/utils/helpers'
import CenterSpinner from '@/components/ui/loader/CenterSpinner'
import { updateUserHistory } from '@/utils/user'
import MainNavigation from '../navigation/Navigation'

const Router = ({ routeComponents, routePaths, defaultRoute = '/', subNavComponent, mainNavItems = [] }) => {
  const routes = useMemo(
    () => [
      ...Object.keys(routeComponents).map((key) => {
        const { filepath, fallback, ...options } = routeComponents[key]
        const Component = filepath ? lazy(() => import(`Routes/${filepath}`)) : () => null
        const path = routePaths[key]
        return {
          ...options,
          path,
          element: (
            <Suspense fallback={fallback?.(path) || <CenterSpinner />}>
              <Component />
            </Suspense>
          ),
        }
      }),
      { path: '*', element: <Navigate to={defaultRoute} replace /> },
    ],
    [routeComponents, routePaths, defaultRoute]
  )
  const childRoutes = useRoutes(routes)
  const location = useLocation()
  const [selectedRoute, setSelectedRoute] = useState()

  useEffect(() => {
    const matches = matchRoutes(routes, location)
    const { path = '' } = (matches && matches[0]?.route) || {}
    document.title = [
      ...path
        .split('/')
        .slice(1)
        .reverse()
        .filter((p) => !p.startsWith(':')),
      SITE_NAME,
    ]
      .map((p) => capitalizePhrase(p, '-'))
      .join(' | ')
    setSelectedRoute(routes.find((a) => a.path === path))
    updateUserHistory(location)
    window.scrollTo({ top: 0, left: 0 })
  }, [location, routes])

  if (!selectedRoute) {
    return null
  }

  const { hideNavigation } = selectedRoute
  const navItems = hideNavigation ? mainNavItems.filter((m) => m.neverHideLink) : mainNavItems

  return (
    <>
      {navItems.length ? <MainNavigation routes={navItems} /> : null}
      {!hideNavigation && subNavComponent ? subNavComponent : null}
      <div role="main" id="main-content" tabIndex={-1}>
        {childRoutes}
      </div>
    </>
  )
}

Router.propTypes = {
  routeComponents: PropTypes.object.isRequired,
  routePaths: PropTypes.object.isRequired,
  defaultRoute: PropTypes.string,
  subNavComponent: PropTypes.node,
  mainNavItems: PropTypes.array,
}

export default Router
