import { useEffect } from 'react'
import Router from '@/components/apps/router/NewRouter'
import CenterSpinner from '@/components/ui/loader/CenterSpinner'
import Error from '@/components/ui/error/Error'
import { useApiCall } from '@/hooks/useApiCall'
import { useFeatureFlags } from '@/hooks/useFeatureFlags'
import * as gqlCache from '@/services/graphql/students/cache'
import { getCurrentEduPeriod } from '@/services/rest/msclepreference'
import { appInitError } from '@/utils/messages'
import { STUDENT_FILEPATHS, STUDENT_MAIN_NAVIGATION, STUDENT_ROUTES } from '@/utils/students'
import getEnvConfig from '@/utils/environment'

const StudentApp = () => {
  const { legacyAppUrl } = getEnvConfig()
  const { isStudentAppEnabled, loading: loadingEnabledFeatures } = useFeatureFlags()
  const refApi = useApiCall(getCurrentEduPeriod, false)

  useEffect(() => {
    if (refApi.data) {
      gqlCache.currentEduPeriodVar(refApi.data)
    }
  }, [refApi.data])

  useEffect(() => {
    if (!loadingEnabledFeatures && !isStudentAppEnabled) {
      window.location.href = legacyAppUrl
    }
  }, [loadingEnabledFeatures, isStudentAppEnabled, legacyAppUrl])

  if (loadingEnabledFeatures || !isStudentAppEnabled)
    return (
      <div className="position-absolute top-0 left-0 bottom-0 right-0 cb-white-bg z-index-999">
        <CenterSpinner />
      </div>
    )

  if (refApi.loading) return <CenterSpinner />

  if (refApi.error) return <Error {...appInitError} wrapperClass="cb-margin-top-72 col-sm-12" />

  return <Router routesConfig={STUDENT_ROUTES} filepaths={STUDENT_FILEPATHS} mainNavItems={STUDENT_MAIN_NAVIGATION} />
}

export default StudentApp
