/**
 * A custom hook that facilitates making API calls
 *
 * @param {Function} apiFunc - A function that returns a Promise, representing the API call to be made
 * @param {Boolean} [lazy=true] - A boolean value indicating whether the API call should be made immediately (`lazy=false`)
 *
 * @returns {Object} An object with the following properties:
 *  - fetch: A function that can be used to make the API call. Will return data when invoked.
 *  - loading: A boolean indicating whether the API call is currently in progress
 *  - data: The data returned from the API, if the call was successful
 *  - error: An error object, if the API call failed
 *  - loaded: A boolean indicating whether the API call has already completed and data is available.
 *  - called: The number of times the API call has been executed.
 */
export const useApiCall = (apiFunc, lazy = true, skip = false) => {
  const [called, setCalled] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState(null)

  const execute = useCallback(async () => {
    let response
    try {
      setLoading(true)
      setError(null)
      setCalled((prev) => prev + 1)
      response = await apiFunc()
      setData(response)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
    return response
  }, [apiFunc])

  useEffect(() => {
    if (!lazy && !skip) execute()
  }, [execute, lazy, skip])

  return { fetch: execute, loading, data, error, loaded: Boolean(data), called }
}
