import CBDropdown from '@cb/apricot/CBDropdown'
import { Icon, Input } from '@cb/apricot-react'
import classnames from 'classnames'
import escapeRegExp from 'lodash/escapeRegExp'

const FilterDropdown = ({
  title,
  items,
  pinnedItem,
  maxVisibleItems = 10,
  filterThreshold = 10,
  onFilterChange,
  onItemSelect,
  defaultFilter = '',
  disabled,
  closeOnClick = true,
  closeOnClickOutside = true,
}) => {
  const dropdownRef = useRef({})
  const [filter, setFilter] = useState(defaultFilter)
  const id = useId()
  const dropdownId = `filterDropdown-${id}`
  const dropdownMenuId = `filterDropdownMenu-${id}`
  const showFilterInput = items.length > filterThreshold

  const visibleItems = useMemo(() => {
    const filterRegex = new RegExp(escapeRegExp(filter), 'i')
    const filtered = items.filter((f) => filterRegex.test(f.label) && pinnedItem?.label !== f.label)
    if (pinnedItem) filtered.unshift(pinnedItem)
    return filtered.slice(0, maxVisibleItems)
  }, [filter, items, pinnedItem, maxVisibleItems])

  useEffect(() => {
    const plugin = CBDropdown({ elem: dropdownRef?.current, closeOnClick, closeOnClickOutside, callBack: onItemSelect })
    return () => {
      plugin?.destroy()
    }
  }, [closeOnClick, closeOnClickOutside, onItemSelect, filter])

  return (
    <div className={classnames('cb-dropdown', disabled && 'cb-disabled')} ref={dropdownRef}>
      <a
        className="cb-dropdown-toggle"
        id={dropdownId}
        href="#"
        role="button"
        tabIndex={disabled ? -1 : undefined}
        aria-disabled={disabled}
        aria-expanded="false"
        aria-haspopup="true"
        aria-controls={dropdownMenuId}
      >
        <span>{title}</span>
        <Icon name="down" decorative />
        <Icon name="up" className="cb-hidden" decorative />
      </a>
      <div className="cb-dropdown-menu cb-box-shadow">
        {showFilterInput ? (
          <div className="cb-input-container">
            <Input
              label="Search"
              aria-controls={dropdownMenuId}
              clearable
              icon="search"
              iconRight
              role="combobox"
              aria-expanded="true"
              defaultValue={filter}
              onChange={(e) => {
                onFilterChange?.(e)
                setFilter(e.target.value)
              }}
            />
          </div>
        ) : null}
        <ul role="listbox" aria-labelledby={dropdownId} id={dropdownMenuId}>
          {visibleItems.map((item) => (
            <li role="presentation" key={item.id} className={classnames(item.disabled && 'cb-disabled')}>
              <a
                role="option"
                href={item.href || '#'}
                aria-disabled={item.disabled}
                tabIndex={item.disabled ? -1 : undefined}
                className={classnames(
                  item.className,
                  item.icon && 'justify-content-start',
                  item.disabled && 'cb-cursor-default',
                  item.selected && 'cb-text-bold cb-cursor-default'
                )}
                onClick={(e) => {
                  e.preventDefault()
                  if (!item.disabled && !item.selected) {
                    item.onClick?.(e)
                  }
                }}
              >
                {item.icon ? <Icon {...item.icon} decorative className="cb-margin-right-8 cb-no-margin-left" /> : null}
                {item.label}
              </a>
            </li>
          ))}
          {showFilterInput && (
            <li
              key="searchmore"
              className="cb-padding-top-16 cb-padding-bottom-8 cb-padding-left-24 cb-gray2-color cb-text-italic"
            >
              Search to view more
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

FilterDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      href: PropTypes.string,
      disabled: PropTypes.bool,
      icon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
      }),
    })
  ),
  pinnedItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string,
    }),
  }),
  maxVisibleItems: PropTypes.number,
  filterThreshold: PropTypes.number,
  onFilterChange: PropTypes.func,
  disabled: PropTypes.bool,
  defaultFilter: PropTypes.string,
  closeOnClick: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  onItemSelect: PropTypes.func,
}

export default FilterDropdown
