import cx from 'classnames'
import { Breadcrumbs, Crumb } from '@cb/apricot-react'

const Banner = ({ title, subtitle, description, columnSize = 'col-xs-12', className = '', breadcrumbs }) => (
  <>
    <div
      className={cx(
        'cb-purple1-bg cb-white-color',
        !breadcrumbs && 'cb-margin-bottom-72 cb-margin-sm-down-bottom-24',
        className
      )}
    >
      <div className="container cb-padding-top-48 cb-padding-bottom-48">
        <div className="row">
          <div className={columnSize}>
            {subtitle ? (
              <div className="cb-margin-bottom-8">
                <span className="cb-border-top cb-padding-top-8 cb-text-uppercase cb-h6">{subtitle}</span>
              </div>
            ) : null}
            <h1 className="cb-h1-lite cb-font-weight-regular">{title}</h1>
            {description ? (
              <div className="cb-paragraph2-res cb-font-weight-light cb-margin-top-16 cb-margin-sm-down-8">
                {description}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
    {breadcrumbs ? (
      <div className="container cb-margin-bottom-48">
        <Breadcrumbs pageTitle={breadcrumbs.pageTitle}>
          {breadcrumbs.crumbs.map((opts, i) => (
            <Crumb {...opts} key={i} />
          ))}
        </Breadcrumbs>
      </div>
    ) : null}
  </>
)

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  columnSize: PropTypes.string,
  className: PropTypes.string,
  breadcrumbs: PropTypes.object,
}

export default Banner
