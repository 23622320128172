class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    return { error: error.toString() }
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error)
      return (
        <div className="container cb-padding-top-48 cb-padding-bottom-48">
          <h1>Uh oh!</h1>
          <p className="cb-margin-top-8">You broke it. To fix, refresh the page.</p>
        </div>
      )

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

export default ErrorBoundary
