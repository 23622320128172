import { Link, useLocation, useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import { Dropdown, DropdownItem, Icon } from '@cb/apricot-react'

const NavigationItem = ({ title, paths, path, icon }) => {
  const { pathname } = useLocation()
  const isActive = (itemPath) => pathname === itemPath
  const navigate = useNavigate()

  return paths ? (
    <li className="cb-nav-link">
      <Dropdown title={title} closeOnClick>
        {paths.map((p) => (
          <DropdownItem key={p.path} label={p.title} onClick={() => navigate(p.path)} aria-current={isActive(p.path)} />
        ))}
      </Dropdown>
    </li>
  ) : (
    <li className="cb-nav-link cb-procedural">
      <Link
        to={path}
        className={classnames('cb-menu-link', isActive(path) && 'cb-selected')}
        aria-current={isActive(path)}
      >
        <span>
          {icon ? <Icon decorative name={icon} className="cb-margin-right-8" /> : null}
          {title}
        </span>
      </Link>
    </li>
  )
}

NavigationItem.propTypes = {
  title: PropTypes.string.isRequired,
  paths: PropTypes.array,
  path: PropTypes.string,
  icon: PropTypes.string,
}

export default NavigationItem
