import { formatDay, isFutureDate, calculateAge } from '@clep/utilities/helpers'
import { COUNTRY_CODE, VOUCHER_STATUS } from '../../../utils/constants'
import { formatGender, formatPhone } from '../../../utils/helpers'

import { currentEduPeriodVar, profileFormVar, registrationFormsVar } from './cache'

export default {
  Query: {
    fields: {
      commonData: {
        read() {
          return {
            currentEduPeriod: currentEduPeriodVar(),
          }
        },
      },
      registrationForms: {
        read() {
          return registrationFormsVar()
        },
      },
      profileForm: {
        read() {
          return profileFormVar()
        },
      },
    },
  },
  Student: {
    fields: {
      derivedFields: {
        read(_, { readField }) {
          // birthDt, hsGradDt are all coming in 'yyyy-MM-dd' format
          const hsGradDt = readField('hsGradDt')
          const isFutureGradDate = isFutureDate({ date: hsGradDt, ignoreDay: true })
          const phone = readField('phone') || {}
          const address = readField('address') || {}
          const birthDt = readField('birthDt')
          const age = calculateAge({ date: birthDt, asOfDate: new Date() })
          const isUnderEighteenFromIl = `${address?.stateCd}`.toUpperCase() === 'IL' && age < 18
          const isCountryUS = `${address?.countryCd}`.toUpperCase() === COUNTRY_CODE.US

          return {
            isFutureGradDate,
            dateOfBirth: formatDay({
              date: new Date(birthDt),
              isUTC: true,
              format: 'MMMM dd, yyyy',
            }),
            hsGradDate: hsGradDt && formatDay({ date: new Date(hsGradDt), isUTC: true, format: 'MMMM yyyy' }),
            legalName: `${readField('firstName')} ${readField('lastName')}`,
            gender: formatGender(readField('genderCd')),
            phoneNumber: formatPhone(phone.number),
            addressLine3: `${address.city || ''}, ${address.stateCd || address.province || ''} ${
              address.postalCd || ''
            }`,
            isOlderThanThirteen: age >= 13,
            isUnderEighteenFromIl,
            isCountryUS,
          }
        },
      },
    },
  },
  Voucher: {
    fields: {
      isActive: {
        read(_, { readField }) {
          return readField('status') === VOUCHER_STATUS.ACTIVE
        },
      },
    },
  },
}

export const canSelectTestingLocation = (profile, isDantes = Boolean(profile?.dantes)) =>
  profile?.derivedFields?.isOlderThanThirteen &&
  ((profile?.derivedFields?.isCountryUS && !profile?.derivedFields?.isUnderEighteenFromIl) || isDantes)

export const isIntNonDantes = (profile, isDantes = profile?.derivedFields?.isDantes) =>
  !profile?.derivedFields?.isCountryUS && !isDantes
