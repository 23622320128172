import { lazy, Suspense } from 'react'
import { Navigate, useLocation, useRoutes, matchRoutes } from 'react-router-dom'
import { SITE_NAME } from '@/utils/constants'
import { capitalizePhrase } from '@/utils/helpers'
import CenterSpinner from '@/components/ui/loader/CenterSpinner'
import { updateUserHistory } from '@/utils/user'
import MainNavigation from '../navigation/Navigation'

const buildRoute = (route, routesConfig, filepaths, parent) => {
  const filepath = filepaths?.[route]
  const config = routesConfig[route]
  const { path, fallback, children, ...options } = config
  const Component = filepath && lazy(() => import(`Routes/${filepath}`))

  return {
    ...options,
    element: Component ? (
      <Suspense fallback={fallback?.(config) || parent?.fallback?.(config) || <CenterSpinner />}>
        <Component />
      </Suspense>
    ) : null,
    path: children ? undefined : path, // should be undefined for parent components
    children: children && Object.keys(children).map((c) => buildRoute(c, children, filepaths, config)),
  }
}

const Router = ({ routesConfig, filepaths, mainNavItems, subNavComponent }) => {
  const config = useMemo(() => {
    const keys = Object.keys(routesConfig)
    const defaultPath = routesConfig[keys.find((r) => routesConfig[r]?.default)]?.path
    return [
      ...keys.map((r) => buildRoute(r, routesConfig, filepaths)),
      { path: '*', element: <Navigate to={defaultPath} replace /> },
    ]
  }, [routesConfig, filepaths])
  const routes = useRoutes(config)
  const location = useLocation()
  const [selectedRoute, setSelectedRoute] = useState()

  useEffect(() => {
    const matches = matchRoutes(config, location)
    const match = matches?.find((m) => m.pathname === location.pathname) || {}
    document.title = [
      ...location.pathname
        .split('/')
        .slice(1)
        .reverse()
        .filter((p) => !p.startsWith(':')),
      SITE_NAME,
    ]
      .map((p) => capitalizePhrase(p, '-'))
      .join(' | ')
    setSelectedRoute(match.route)
    updateUserHistory(location)
    window.scrollTo({ top: 0, left: 0 })
  }, [location, config])

  if (!selectedRoute) {
    return null
  }

  const { hideNavigation } = selectedRoute
  const navItems = hideNavigation ? mainNavItems.filter((m) => m.neverHideLink) : mainNavItems

  return (
    <>
      {navItems.length ? <MainNavigation routes={navItems} /> : null}
      {!hideNavigation && subNavComponent ? subNavComponent : null}
      <div role="main" id="main-content" tabIndex={-1}>
        {routes}
      </div>
    </>
  )
}

Router.propTypes = {
  routesConfig: PropTypes.object.isRequired,
  filepaths: PropTypes.object.isRequired,
  subNavComponent: PropTypes.node,
  mainNavItems: PropTypes.array,
}

export default Router
