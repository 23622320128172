import '@cb/apricot/CBBase'
import '@cb/apricot/CBCard'
import '@cb/apricot/CBGrid'
import '@cb/apricot/CBLogo'
import '@cb/apricot/CBTab'
import '@cbw/widgets'

import './assets/scss/apricot-helpers.scss'

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import ErrorBoundary from './components/apps/error-boundary/ErrorBoundary'

const appContainer = document.getElementById('root')
const root = createRoot(appContainer)

root.render(
  <>
    <cbw-header
      app-id={Config.CLEP_APP_ID}
      site-title="CLEP"
      program-type="higher-ed"
      site-type="gh"
      use-cb-logo-link="false"
      use-search-widget={false}
      skip-target-id="main-content"
    ></cbw-header>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
    <cbw-footer></cbw-footer>
  </>
)
