import { ApolloProvider } from '@apollo/client'
import CBInputMethod from '@cb/apricot/CBInputMethod'
import Error from './components/ui/error/Error'
import CenterSpinner from './components/ui/loader/CenterSpinner'
import ProfessionalApp from './pages/professionals/app/ProfessionalApp'
import Login from './components/apps/login/Login'
import { isProfessional, isStudent } from './utils/user'
import { appInitError, accessDeniedError } from './utils/messages'
import { appsyncClient } from './services/graphql/client'
import StudentApp from './pages/students/app/StudentApp'
import { useAuth } from './hooks/useAuth'

const App = () => {
  const { loggedOut, loading, error } = useAuth()

  useEffect(() => {
    CBInputMethod() // adds extra accessibility elements when using keyboard
  }, [])

  if (loading) return <CenterSpinner />

  if (error) return <Error {...appInitError} wrapperClass="cb-margin-top-72 col-sm-12" />

  if (loggedOut) return <Login />

  if (isProfessional())
    return (
      <ApolloProvider client={appsyncClient()}>
        <ProfessionalApp />
      </ApolloProvider>
    )

  if (isStudent())
    return (
      <ApolloProvider client={appsyncClient()}>
        <StudentApp />
      </ApolloProvider>
    )

  return <Error {...accessDeniedError} wrapperClass="cb-margin-top-72 col-sm-12" />
}

export default App
