import sortBy from 'lodash/sortBy'

import { currentEduPeriodVar, orderFormVar, selectedOrgVar, selectedSchoolVar } from './cache'

export default {
  Query: {
    fields: {
      getAcceptedTermsAndConditions: {
        merge: true,
      },
      getOrgs: {
        merge(_, incoming) {
          return sortBy(incoming, ['orgName'])
        },
      },
      getOrgHierarchy: {
        merge(_, incoming) {
          return sortBy(incoming, ['orgName']).filter((org) => org.highSchoolInd === true)
        },
      },
      commonData: {
        read() {
          return {
            currentEduPeriod: currentEduPeriodVar(),
            selectedOrg: selectedOrgVar(),
            selectedSchool: selectedSchoolVar(),
          }
        },
      },
      orderForm: {
        read() {
          return orderFormVar()
        },
      },
    },
  },
  VoucherOrderUpdatableFields: {
    fields: {
      updatableFields: {
        merge(_, incoming) {
          return (incoming || []).map((serverField) => {
            if (serverField === 'purchaseOrderNumber') {
              return 'poNumber'
            }

            return serverField
          })
        },
      },
    },
  },
  TermsAndConditions: {
    keyFields: ['catapultId'],
  },
}
