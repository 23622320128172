import Banner from '../banner/Banner'

const BannerFallback = (props) => (
  <>
    <Banner {...props} />
    <div className="cb-margin-top-72 cb-height-50-vh" />
  </>
)

BannerFallback.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default BannerFallback
