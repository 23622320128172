import { canViewApp, getAllowedOrgs, isDistrict } from '@/utils/user'
import * as gqlCache from '@/services/graphql/professionals/cache'
import { useCustomQuery } from '@/services/graphql/utils'
import {
  GET_ACCEPTED_TC,
  GET_CACHED_COMMON_DATA,
  GET_ORG_HIERARCHY,
  GET_ORG_PROFILE,
  GET_ORGS_BY_ID,
} from '@/services/graphql/professionals/queries'
import { useApiCall } from '@/hooks/useApiCall'
import { getEduPeriods } from '@/services/rest/msclepreference'
import { useEffect } from 'react'

const useProfessionalAppData = () => {
  const { data: { currentEduPeriod, selectedOrg } = {} } = useCustomQuery(GET_CACHED_COMMON_DATA)
  const isDistrictUser = useMemo(() => isDistrict(), [])
  const isAuthorized = useMemo(() => canViewApp(), [])
  const allowedOrgIds = useMemo(() => getAllowedOrgs(), [])
  const orgId = selectedOrg?.orgId

  const getAcceptedTermsQuery = useCustomQuery(GET_ACCEPTED_TC, { skip: !isAuthorized })
  const hasAcceptedTC = Boolean(getAcceptedTermsQuery.data?.accepted)
  const getAllowedOrgsQuery = useCustomQuery(GET_ORGS_BY_ID, {
    variables: { orgIds: allowedOrgIds },
    skip: !hasAcceptedTC,
  })
  const getEduPeriodsCall = useApiCall(getEduPeriods, false, !hasAcceptedTC)
  const getOrgProfileQuery = useCustomQuery(GET_ORG_PROFILE, {
    variables: { orgId, educationPeriodCd: currentEduPeriod?.code },
    skip: !currentEduPeriod || !orgId,
  })
  const getOrgTreeQuery = useCustomQuery(GET_ORG_HIERARCHY, {
    variables: { orgId },
    skip: !(isDistrictUser && orgId),
  })
  const getOrgDetailsQuery = useCustomQuery(GET_ORGS_BY_ID, {
    variables: { orgIds: [orgId] },
    skip: !orgId,
  })

  const allowedOrgs = getAllowedOrgsQuery.data
  const eduPeriods = getEduPeriodsCall.data
  const orgProfile = getOrgProfileQuery.data
  const orgTreeItems = getOrgTreeQuery.data
  const orgDetails = getOrgDetailsQuery.data
  useEffect(() => {
    if (allowedOrgs?.length > 0) gqlCache.selectedOrgVar(allowedOrgs[0])
  }, [allowedOrgs])
  useEffect(() => {
    if (eduPeriods?.length > 0 && !gqlCache.currentEduPeriodVar())
      gqlCache.currentEduPeriodVar(eduPeriods.find((x) => x.current))
  }, [eduPeriods])
  useEffect(() => {
    if (orgDetails) gqlCache.selectedOrgVar(orgDetails[0])
  }, [orgDetails])

  const loading =
    getAcceptedTermsQuery.loading ||
    getAllowedOrgsQuery.loading ||
    getEduPeriodsCall.loading ||
    getOrgProfileQuery.loading ||
    getOrgTreeQuery.loading ||
    getOrgDetailsQuery.loading
  const error =
    getAcceptedTermsQuery.error ||
    getAllowedOrgsQuery.error ||
    (getAllowedOrgsQuery.loaded && allowedOrgs.length === 0) ||
    getEduPeriodsCall.error ||
    getOrgProfileQuery.error ||
    getOrgTreeQuery.error ||
    getOrgDetailsQuery.error
  const loaded =
    Boolean(selectedOrg?.orgId) &&
    Boolean(currentEduPeriod) &&
    getOrgProfileQuery.loaded &&
    (!isDistrictUser || getOrgTreeQuery.loaded) &&
    getOrgDetailsQuery.loaded

  return {
    isAuthorized,
    isDistrictUser,
    loading: loading || (hasAcceptedTC && !error && !loaded),
    error,
    hasAcceptedTC,
    allowedOrgs,
    eduPeriods,
    orgProfile,
    orgTreeItems,
  }
}

export default useProfessionalAppData
